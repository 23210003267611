import { formatNoteSymbol, NODE_TEXT_MODES } from './Diagram';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLineHeight, faGear, faChevronLeft, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { ReactComponent as TuningForkIcon } from './images/Tuning-Fork.svg';
import { useState, useEffect } from 'react';


export const NOTES = [
  'A',
  ['A#', 'Bb'],
  'B',
  'C',
  ['C#', 'Db'],
  'D',
  ['D#', 'Eb'],
  'E',
  'F',
  ['F#', 'Gb'],
  'G',
  ['G#', 'Ab']
];

export const NOTE_TO_INDEX = NOTES.reduce((acc, note, index) => {
  if (Array.isArray(note)) {
    // Handle sharps and flats
    note.forEach(noteName => {
      acc[noteName] = index;
    });
  } else {
    // Handle single notes
    acc[note] = index;
  }
  return acc;
}, {});

export const INSTRUMENT_PRESETS = [
  { instrument: "Guitar", strings: 6, tuning: ['E2', 'A2', 'D3', 'G3', 'B3', 'E4'] },
  { instrument: "Guitar", strings: 7, tuning: ['B1', 'E2', 'A2', 'D3', 'G3', 'B3', 'E4'] },
  { instrument: "Guitar", strings: 8, tuning: ['F#1', 'B1', 'E2', 'A2', 'D3', 'G3', 'B3', 'E4'] }, // Add this line
  { instrument: "Bass", strings: 4, tuning: ['E1', 'A1', 'D2', 'G2'] },
  { instrument: "Bass", strings: 5, tuning: ['B0', 'E1', 'A1', 'D2', 'G2'] },
  { instrument: "Bass", strings: 6, tuning: ['B0', 'E1', 'A1', 'D2', 'G2', 'C3'] },
  { instrument: "Bass", strings: 7, tuning: ['B0', 'E1', 'A1', 'D2', 'G2', 'C3', 'F3'] },
  { instrument: "Ukulele", strings: 4, tuning: ['G4', 'C4', 'E4', 'A4'] },
  { instrument: "Violin", strings: 4, tuning: ['G3', 'D4', 'A4', 'E5'] },
  { instrument: "Viola", strings: 4, tuning: ['C3', 'G3', 'D4', 'A4'] },
  { instrument: "Cello", strings: 4, tuning: ['C2', 'G2', 'D3', 'A3'] },
  { instrument: "Mandolin", strings: 4, tuning: ['G3', 'D4', 'A4', 'E5'] },
  { instrument: "Banjo", strings: 4, tuning: ['D3', 'G3', 'B3', 'D4'] },
  { instrument: "Custom", strings: 3, tuning: ['G3', 'D4', 'A4'] },
  { instrument: "Custom", strings: 2, tuning: ['D4', 'A4'] },
  { instrument: "Custom", strings: 1, tuning: ['E4'] },
];

// Add a helper function to generate the display label
const getPresetLabel = (instrument, strings) => `${instrument} • ${strings} String`;

// Update these scale definitions
export const SCALES = {
  'None': null,
  'Chromatic': [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  'Wholetone': [0, 2, 4, 6, 8, 10],
  'Diminished': [0, 2, 3, 5, 6, 8, 9, 11],  // Whole-Half pattern
  'Pentatonic': [0, 2, 4, 7, 9],
  'Blues': [0, 3, 5, 6, 7, 10],
  'Major': [0, 2, 4, 5, 7, 9, 11],
  'Melodic Minor': [0, 2, 3, 5, 7, 9, 11],
  'Harmonic Minor': [0, 2, 3, 5, 7, 8, 11],
  'Harmonic Major': [0, 2, 4, 5, 7, 8, 11],
};

// Add this constant for Nashville numbers
export const NASHVILLE_NUMBERS = {
  'Major': [
    'I',    // Major
    'ii',   // minor
    'iii',  // minor
    'IV',   // Major
    'V',    // Major
    'vi',   // minor
    'vii°'  // diminished
  ]
};

// Update helper function to check if a note is in scale
export const isNoteInScale = (note, rootNote, scale) => {
  // Return false when no scale is selected or scale is None
  if (!scale) return false;
  
  // Show all notes for Chromatic scale
  if (scale === SCALES.Chromatic) return true;
  
  if (!note || !rootNote) return false;
  
  const rootIndex = NOTE_TO_INDEX[rootNote];
  const noteIndex = NOTE_TO_INDEX[note];
  
  if (rootIndex === undefined || noteIndex === undefined) return false;
  
  // Calculate the interval between root and note
  const interval = (noteIndex - rootIndex + 12) % 12;
  return scale.includes(interval);
};

// Add mode names in order
export const MAJOR_MODES = [
  'Major (Ionian)',      // 1st degree
  'Dorian',      // 2nd degree
  'Phrygian',    // 3rd degree
  'Lydian',      // 4th degree
  'Mixolydian',  // 5th degree
  'Natural Minor (Aeolian)',     // 6th degree
  'Locrian'      // 7th degree
];

// Update MELODIC_MINOR_MODES
export const MELODIC_MINOR_MODES = [
  'Melodic Minor',    // 1st degree
  'Dorian ♭2',       // Changed from b2
  'Lydian Augmented', // 3rd degree
  'Lydian Dominant',  // 4th degree
  'Mixolydian ♭6',   // Changed from b6
  'Aeolian ♭5',      // Changed from b5
  'Altered Scale'     // 7th degree
];

// Update HARMONIC_MINOR_MODES
export const HARMONIC_MINOR_MODES = [
  'Harmonic Minor',      // 1st degree
  'Locrian ♮6',         // Already correct
  'Ionian Augmented',    // 3rd degree
  'Dorian ♯4',          // Changed from #4
  'Phrygian Dominant',   // 5th degree
  'Lydian ♯2',          // Changed from #2
  'Super Locrian ♭♭7'   // Changed from bb7
];

// Update HARMONIC_MAJOR_MODES
export const HARMONIC_MAJOR_MODES = [
  'Harmonic Major',      // 1st degree
  'Dorian ♭5',          // Changed from b5
  'Phrygian ♭4',        // Changed from b4
  'Lydian ♭3',          // Changed from b3
  'Mixolydian ♭2',      // Changed from b2
  'Lydian Augmented ♯2', // Changed from #2
  'Locrian ♭♭7'         // Changed from bb7
];

// Add this constant at the top with other constants
const FLAT_KEYS = ['F', 'Bb', 'Eb', 'Ab', 'Db', 'Gb'];

// Add this constant with the other mode constants
export const PENTATONIC_MODES = [
  'Major Pentatonic',        // 1st degree
  '',    // 2nd degree
  '',             // 3rd degree
  '',             // 4th degree
  'Minor Pentatonic'         // 5th degree
];

  // Update this helper function near other utility functions
  export const getChordQuality = (activeIntervals, scaleDisplayMode) => {
  // Convert Set to sorted Array for easier pattern matching
  const intervals = Array.from(activeIntervals).sort((a, b) => a - b);
  
  // Check for basic intervals
  const hasRoot = intervals.includes(0);
  const hasMinorThird = intervals.includes(3);
  const hasMajorThird = intervals.includes(4);
  const hasDimFifth = intervals.includes(6);
  const hasPerfectFifth = intervals.includes(7);
  const hasAugFifth = intervals.includes(8);
  const hasMinorSeventh = intervals.includes(10);
  const hasMajorSeventh = intervals.includes(11);
  
  if (!hasRoot) return '';
  
  // Seventh chord qualities
  if (scaleDisplayMode === SCALE_DISPLAY_MODES.TETRACHORDS) {
    // Major 7th
    if (hasMajorThird && hasPerfectFifth && hasMajorSeventh) {
      return 'maj7';
    }
    // Minor 7th
    if (hasMinorThird && hasPerfectFifth && hasMinorSeventh) {
      return 'm7';
    }
    // Dominant 7th
    if (hasMajorThird && hasPerfectFifth && hasMinorSeventh) {
      return '7';
    }
    // Half-diminished (Minor 7 b5)
    if (hasMinorThird && hasDimFifth && hasMinorSeventh) {
      return 'm7♭5';
    }
    // Augmented 7th
    if (hasMajorThird && hasAugFifth && hasMinorSeventh) {
      return 'aug7';
    }
    // Augmented major 7th
    if (hasMajorThird && hasAugFifth && hasMajorSeventh) {
      return 'aug(maj7)';
    }
    // Minor-Major 7th
    if (hasMinorThird && hasPerfectFifth && hasMajorSeventh) {
      return 'm(maj7)';
    }
  } else {
    // Original triad qualities
    if (hasMajorThird && hasPerfectFifth) return ''; // Return empty string for major triads
    if (hasMinorThird && hasPerfectFifth) return 'm';
    if (hasMinorThird && hasDimFifth) return 'dim';
    if (hasMajorThird && hasAugFifth) return 'aug';
  }
  
  // Return empty string if no recognized pattern
  return '';
};

// Add helper function to get key options
const getKeyOptions = (scale) => {
  if (scale === 'Chromatic') return ['C'];
  if (scale === 'Wholetone') return ['1', '2'];
  if (scale === 'Diminished') return ['Whole-Half', 'Half-Whole'];
  return NOTES.map(note => Array.isArray(note) ? note[0] : note);
};

// Update helper function for enharmonic spelling
const getEnharmonicSpelling = (note, key, scale) => {
  if (!Array.isArray(NOTES[NOTE_TO_INDEX[note]])) return note;
  
  // Get both possible spellings
  const [sharpSpelling, flatSpelling] = NOTES[NOTE_TO_INDEX[note]];
  
  // Use flats for flat keys
  if (FLAT_KEYS.includes(key)) {
    return flatSpelling;
  }
  
  // Use sharps for other keys
  return sharpSpelling;
};

// Add this constant for interval names
export const INTERVAL_NAMES = {
  0: 'R',
  1: '♭2',
  2: '2',
  3: '♭3',
  4: '3',
  5: '4',
  6: '♭5',
  7: '5',
  8: '♭6',
  9: '6',
  10: '♭7',
  11: '7'
};

export const NOTE_COLORS = {
  rainbow: [
    '#FF0000', // Red (Root)
    '#E600CF', // Magenta
    '#E600CF', // Magenta
    '#007FFF', // Azure
    '#007FFF', // Azure
    '#00D96B', // Green
    '#9FFF00', // Yellow-Green
    '#FFFF00', // Yellow
    '#FFBF00', // Orange-Yellow
    '#FFBF00', // Orange-Yellow
    '#FF5F00', // Red-Orange
    '#FF5F00', // Red-Orange
  ]
};


// First, add a constant for the display modes
const SCALE_DISPLAY_MODES = {
  FULL: 'full',
  FIFTHS: 'fifths',
  TRIADS: 'triads',
  TETRACHORDS: 'tetrachords'
};

// Add this constant for Wholetone scale roots
const WHOLETONE_ROOTS = {
  '1': 'C',
  '2': 'C#'
};

// Add this constant alongside WHOLETONE_ROOTS
const DIMINISHED_ROOTS = {
  'Whole-Half': 'C',
  'Half-Whole': 'C#'
};

// Add this helper function to determine which diminished pattern is selected
const getDiminishedPattern = (root) => {
  if (!root) return 'Whole-Half'; // default
  return root === DIMINISHED_ROOTS['Whole-Half'] ? 'Whole-Half' : 'Half-Whole';
};

// Update the gear menu styles
const gearMenuStyles = {
  overflow: 'hidden',
  transition: 'max-height 0.3s ease-out',
  maxHeight: 0,
};

const gearMenuExpandedStyles = {
  ...gearMenuStyles,
  maxHeight: '100px', // Adjust based on content height
};

// Add constants for roman numerals of each scale type
export const SCALE_DEGREES = {
  'Major': [
    'I',    // Major
    'ii',   // minor
    'iii',  // minor
    'IV',   // Major
    'V',    // Major
    'vi',   // minor
    'vii°'  // diminished
  ],
  'Melodic Minor': [
    'i',    // minor tonic
    'ii',   // minor
    '♭III+', // augmented
    'IV',   // Major
    'V',    // Major
    'vi°',  // diminished
    'vii°'  // diminished
  ],
  'Harmonic Minor': [
    'i',     // minor
    'ii°',   // diminished
    '♭III+', // augmented
    'iv',    // minor
    'V',     // Major
    '♭VI',   // Major
    'vii°'   // diminished
  ],
  'Harmonic Major': [
    'I',     // Major
    'ii°',   // diminished
    'iii',   // minor
    'IV',    // Major
    'V',     // Major
    '♭VI',   // Major
    'vii°'   // diminished
  ]
};

const TheoryControls = ({ 
  selectedKey = null,
  selectedScale = 'None',
  onRootChange, 
  onScaleChange,
  theme,
  onNoteColorChange,
  selectedColor,
  scaleRoot,
  onScaleRootChange,
  rootIndicator = 'circle',
  onRootIndicatorChange,
  onAccidentalStyleChange,
  setShowNodes,
  onInfiniteModeChange,
  currentInstrument,
  currentPreset,
  onInstrumentChange,
  isInverted,
  onInvertChange,
  isInfiniteFrets,
  onFixedChange,
  noteColors,
  onColorModeChange,
  colorMode,
  onThemeChange,
  isDarkMode,
  nodeTextMode,
  onNodeTextModeChange,
  showBounds,
  onShowBoundsChange,
  scaleDisplayMode = SCALE_DISPLAY_MODES.FULL,
  onScaleDisplayModeChange = () => {},
  unit,
  clickMode,
  nodeDisplayMode,
  onNodeDisplayModeChange,
  showGearMenu,
  onGearClick,
  isTuningMode = false,
  onTuningModeChange,
  currentTuning,
  onResetTuning,
  showFadedNotes = true,
  setShowFadedNotes = () => {},
}) => {

  // Add state for animation
  const [isAnimating, setIsAnimating] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (showGearMenu) {
      setShouldRender(true);
      // Add small delay before starting animation
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setIsAnimating(true);
        });
      });
    } else {
      setIsAnimating(false);
      // Turn off tuning mode when gear menu is hidden
      if (isTuningMode) {
        onTuningModeChange(false);
      }
      // Wait for animation to finish before removing from DOM
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 300); // Match transition duration
      return () => clearTimeout(timer);
    }
  }, [showGearMenu, isTuningMode, onTuningModeChange]);

  const controlPanelGroup = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: `${unit * 0.5}px`,
    padding: `${unit * 0.5}px`,
    backgroundColor: theme.controlPanelGroupBg,
    borderRadius: `${unit * 1}px`,
  };

  const selectStyle = {
    backgroundColor: theme.buttonBg,
    color: theme.buttonColor,
    fontSize: `${unit * 1}px`,
    border: 'none',
    padding: '5px 10px',
    fontWeight: 'bold',
    borderRadius: `${unit * 0.5}px`,
    cursor: 'pointer',
    height: `${unit * 1.75}px`,
    outline: 'none',
    flex: 1,
  };

  const instrumentControlsStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '100%',
  };

  // Update helper function to get formatted key list in chromatic order starting with Ab
  const getFormattedKeys = () => {
    const keys = ['Ab', 'A', 'Bb', 'B', 'C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G'];
    return keys.map(key => ({
      value: key,
      display: formatNoteSymbol(key, null, null, null)
    }));
  };

  // Update handler for key changes
  const handleKeyChange = (value) => {
    // Special handling for Wholetone scale
    if (selectedScale === 'Wholetone') {
      const actualRoot = WHOLETONE_ROOTS[value];
      onRootChange(actualRoot);
      onScaleRootChange(null);
      return;
    }

    // Special handling for Diminished scale
    if (selectedScale === 'Diminished') {
      const actualRoot = DIMINISHED_ROOTS[value];
      onRootChange(actualRoot);
      onScaleRootChange(null);
      return;
    }

    // If changing to "None", only update the root without affecting scale or nodes
    if (!value) {
      onRootChange(null);
      return;
    }

    onRootChange(value);
    onScaleRootChange(null);  // Reset scale root only when selecting a new key
    
    // Use flats for flat keys, sharps for others
    if (FLAT_KEYS.includes(value)) {
      onAccidentalStyleChange('flat');
    } else {
      onAccidentalStyleChange('sharp');
    }

    // Turn off infinite mode when a key is selected
    if (selectedScale === 'Major') {
      onInfiniteModeChange?.(false);
    }
  };

  // Update the scale change handler
  const handleScaleChange = (newScale) => {
    // Add check for scales with defined degrees
    const hasScaleDegrees = ['Major', 'Melodic Minor', 'Harmonic Minor', 'Harmonic Major'].includes(newScale);
    if (!hasScaleDegrees && nodeTextMode === NODE_TEXT_MODES.NUMERALS) {
      onNodeTextModeChange(NODE_TEXT_MODES.NOTES);
    }

    if (newScale === 'None') {
      onRootChange(null);
      onScaleRootChange(null);
      onScaleChange(newScale);
      setShowNodes(false);
      onInfiniteModeChange?.(false);
    } else {
      // Force FULL display mode for Chromatic scale
      if (newScale === 'Chromatic') {
        onScaleDisplayModeChange(SCALE_DISPLAY_MODES.FULL);
      }
      
      // Special handling for Wholetone and Diminished scales
      if (newScale === 'Wholetone') {
        onRootChange('C');
        onScaleRootChange(null);
        onScaleChange(newScale);
        setShowNodes(true);
        onInfiniteModeChange?.(false);
        return;
      }
      
      if (newScale === 'Diminished') {
        onRootChange(DIMINISHED_ROOTS['Whole-Half']); // Default to Whole Half
        onScaleRootChange(null);
        onScaleChange(newScale);
        setShowNodes(true);
        onInfiniteModeChange?.(false);
        return;
      }
      
      // Original logic for other scales
      const needsKey = newScale !== 'Chromatic';
      const rootToUse = needsKey 
        ? (selectedKey || 'G')
        : null;
      
      onRootChange(rootToUse);
      onScaleRootChange(null);
      onScaleChange(newScale);
      setShowNodes(true);
      
      const shouldBeInfinite = newScale === 'Major' && !selectedKey && rootToUse === null;
      onInfiniteModeChange?.(shouldBeInfinite);
    }
  };

  // Update the handler for tuning mode toggle
  const handleTuningModeToggle = () => {
    if (!isTuningMode) {
      // When entering tuning mode:
      onScaleRootChange(null);  // Clear selected root note
      onNodeTextModeChange(NODE_TEXT_MODES.NOTES);  // Set text mode to NOTES
      setShowNodes(true);
    }
    onTuningModeChange();
  };

  // Update helper function to check if tuning differs from default
  const isTuningDifferent = () => {
    if (!currentTuning) return false;  // Add null check for currentTuning
    
    const defaultPreset = INSTRUMENT_PRESETS.find(
      preset => preset.instrument === currentPreset.instrument && 
                preset.strings === currentPreset.strings
    );
    
    if (!defaultPreset) return false;
    
    // Compare each string's tuning
    return currentTuning.some((note, index) => note !== defaultPreset.tuning[index]);
  };

  return (
    <>
      <div style={{ display: 'flex', gap: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', flex: 1 }}>
          {clickMode !== 'edit' && (
            <>
              <div style={controlPanelGroup}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  
                  {(selectedScale !== 'None' && 
                    (nodeDisplayMode === 'intervals' || scaleRoot)) && (
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <div style={{ display: 'flex', flex: 1 }}>
                        <button
                          onClick={() => {
                            onScaleRootChange(null);
                            onNodeDisplayModeChange('notes');
                          }}
                          style={{
                            ...selectStyle,
                            flex: '0 0 auto',
                            marginRight: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <select 
                          value={scaleDisplayMode}
                          onChange={(e) => onScaleDisplayModeChange(e.target.value)}
                          style={selectStyle}
                        >
                          <option value={SCALE_DISPLAY_MODES.FULL}>Modes</option>
                          <option value={SCALE_DISPLAY_MODES.FIFTHS}>Fifths</option>
                          <option value={SCALE_DISPLAY_MODES.TRIADS}>Triads</option>
                          <option value={SCALE_DISPLAY_MODES.TETRACHORDS}>Tetrads</option>
                        </select>
                      </div>
                    </div>
                  )}

                  {nodeDisplayMode === 'notes' && !scaleRoot && (
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <select 
                        value={selectedScale}
                        onChange={(e) => handleScaleChange(e.target.value)}
                        style={selectStyle}
                      >
                        {selectedScale === 'None' && <option value="None">Select Scale</option>}
                        {Object.keys(SCALES).map(scale => (
                          scale !== 'None' && (
                            <option key={scale} value={scale}>
                              {scale}
                            </option>
                          )
                        ))}
                      </select>

                      {selectedScale !== 'None' && selectedScale !== 'Chromatic' && (
                        <select 
                          value={(() => {
                            if (selectedScale === 'Wholetone') {
                              return selectedKey === 'C#' ? '2' : '1';
                            }
                            if (selectedScale === 'Diminished') {
                              return getDiminishedPattern(selectedKey);
                            }
                            return selectedKey || '';
                          })()}
                          onChange={(e) => handleKeyChange(e.target.value)}
                          style={selectStyle}
                        >
                          {(() => {
                            if (selectedScale === 'Wholetone') {
                              return ['1', '2'].map(num => (
                                <option key={num} value={num}>{num}</option>
                              ));
                            }
                            if (selectedScale === 'Diminished') {
                              return ['Whole-Half', 'Half-Whole'].map(type => (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              ));
                            }
                            return (
                              <>
                                <option value="">None</option>
                                {getFormattedKeys().map(key => (
                                  <option key={key.value} value={key.value}>
                                    {key.display}
                                  </option>
                                ))}
                              </>
                            );
                          })()}
                        </select>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div style={controlPanelGroup}>
                <div style={instrumentControlsStyle}>
                  <select
                    value={`${currentPreset.instrument}|${currentPreset.strings}`}
                    onChange={onInstrumentChange}
                    style={selectStyle}
                  >
                    {INSTRUMENT_PRESETS.map(preset => (
                      <option 
                        key={`${preset.instrument}-${preset.strings}`} 
                        value={`${preset.instrument}|${preset.strings}`}
                      >
                        {getPresetLabel(preset.instrument, preset.strings)}
                      </option>
                    ))}
                  </select>
                  <button 
                    style={selectStyle} 
                    onClick={onGearClick}
                  >
                    <FontAwesomeIcon icon={faGear} />
                  </button>
                </div>
                
                {shouldRender && (
                  <div style={isAnimating ? gearMenuExpandedStyles : gearMenuStyles}>
                    <div style={{ 
                      marginTop: '10px',
                      display: 'flex',
                      gap: '10px'
                    }}>
                      <button 
                        style={selectStyle} 
                        onClick={onInvertChange}
                      >
                        <FontAwesomeIcon icon={faLineHeight} />
                      </button>
                      <button 
                        style={{
                          ...selectStyle,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '5px',
                          backgroundColor: isTuningMode ? theme.buttonBgActive : theme.buttonBg,
                        }} 
                        onClick={handleTuningModeToggle}
                      >
                        <TuningForkIcon 
                          style={{
                            height: `${unit * 1}px`,
                            width: `${unit * 1}px`,
                            filter: isTuningMode ? 'brightness(1.5)' : 'none',
                          }}
                        />
                      </button>
                    </div>
                    {isTuningDifferent() && (
                      <div style={{ 
                        marginTop: '10px',
                        display: 'flex',
                        gap: '10px'
                      }}>
                        <button 
                          style={selectStyle}
                          onClick={onResetTuning}
                        >
                          Reset tuning
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        {clickMode === 'edit' && (
          <>
            <div style={{ 
              color: theme.textColor, 
              opacity: 0.7, 
              fontSize: `${unit * 0.8}px`,
              textAlign: 'center',
              width: 'auto'
            }}>
              Edit mode coming soon
            </div>
          </>
        )}
        </div>
      </div>
    </>
  );
};

export { SCALE_DISPLAY_MODES };
export default TheoryControls; 