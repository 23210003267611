import React from 'react';

const NotesLogoSVG = `
<?xml version="1.0" encoding="UTF-8"?>
<svg xmlns="http://www.w3.org/2000/svg" baseProfile="tiny" version="1.2" viewBox="0 0 92.2 13.5">
  <g>
      <g>
        <path d="M6.5,12.3V0h1.9l8.6,7.2c.3.2.6.5.8.7.2.2.4.4.6.7,0-.7,0-1.2,0-1.5,0-.3,0-.5,0-.7V0h2.6v12.3h-1.9l-9-7.6c-.2-.2-.4-.4-.6-.5s-.3-.3-.5-.5c0,.4,0,.7,0,1.1,0,.3,0,.6,0,.9v6.6h-2.6Z" fill="currentColor"/>
        <path d="M24.9,2.9c0-1.1.2-1.9.6-2.3C25.9.2,26.7,0,27.9,0h9c1.2,0,2,.2,2.4.6.4.4.6,1.2.6,2.3v6.5c0,1.1-.2,1.9-.6,2.3-.4.4-1.2.6-2.4.6h-9c-1.2,0-2-.2-2.4-.6-.4-.4-.6-1.2-.6-2.3V2.9ZM27.7,9.9h9.4V2.2h-9.4v7.8Z" fill="currentColor"/>
        <path d="M49.6,2.2v10.1h-2.9V2.2h-5.6V0h14.1v2.2h-5.6Z" fill="currentColor"/>
        <path d="M56.6,12.3V0h12.5v2.1h-9.7v2.7h5.7v2.1h-5.7v3h9.8v2.3h-12.7Z" fill="currentColor"/>
        <path d="M83.1,2.1h-8v2.6h7.6c1.2,0,2,.2,2.4.6.4.4.6,1.2.6,2.3v1.7c0,1.1-.2,1.9-.6,2.3-.4.4-1.2.6-2.4.6h-7.6c-1.2,0-2-.2-2.4-.6-.4-.4-.6-1.2-.6-2.3v-.3l2.5-.5v1.4h8.7v-2.8h-7.6c-1.2,0-2-.2-2.4-.6-.4-.4-.6-1.2-.6-2.3v-1.4c0-1.1.2-1.9.6-2.3.4-.4,1.2-.6,2.4-.6h7c1.2,0,1.9.2,2.4.6.4.4.7,1.1.7,2.1v.3l-2.5.6v-1.4Z" fill="currentColor"/>
        <path d="M91.3,8.6h.4c.2,0,.4,0,.4.2s.1.3.1.7v1.9c0,.4,0,.6-.1.7s-.4.2-.9.2h-2.1c-.2,0-.4,0-.4-.2,0-.1-.1-.4-.1-.7v-1.9c0-.4,0-.6.1-.7s.4-.2.9-.2h1.7Z" fill="currentColor"/>
      </g>
  </g>
</svg>
`;

const NotesLogo = () => {
  return (
    <div dangerouslySetInnerHTML={{ __html: NotesLogoSVG }} />
  );
};

export default NotesLogo; 