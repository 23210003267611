import React, { useLayoutEffect, forwardRef } from 'react';
import { SCALE_DEGREES } from './TheoryControls';

// Add these constants at the top of the file
const INLAY_POSITIONS = [0, 3, 5, 7, 9, 12, 15, 17, 19, 21, 24];
const BASE_WAVE_AMPLITUDE = 2; // Base amplitude for thinnest string
const WAVE_DURATION = 1.5; // Duration in seconds
const WAVE_REPEAT_COUNT = "indefinite"; // Make the wave repeat forever

// Add this helper function to format accidentals with proper symbols
const formatAccidental = (note) => {
  return note
    .replace('#', '♯')
    .replace('b', '♭');
};

// Add this helper function to calculate frequency
const getNoteFrequency = (note) => {
  const noteToFreq = {
    'C': 261.63, 'C#': 277.18, 'Db': 277.18, 'D': 293.66, 'D#': 311.13, 'Eb': 311.13,
    'E': 329.63, 'F': 349.23, 'F#': 369.99, 'Gb': 369.99, 'G': 392.00, 'G#': 415.30,
    'Ab': 415.30, 'A': 440.00, 'A#': 466.16, 'Bb': 466.16, 'B': 493.88
  };

  // Extract note name and octave (default to 4 if no octave specified)
  const match = note.match(/([A-G][#b]?)(\d+)?/);
  if (!match) return 440; // Default to A4 if no match

  const [, noteName, octave = '4'] = match;
  const baseFreq = noteToFreq[noteName];
  if (!baseFreq) return 440;

  // Calculate octave difference from middle octave (4)
  const octaveDiff = parseInt(octave) - 4;
  return baseFreq * Math.pow(2, octaveDiff);
};

// Add this near the top of DiagramSVG component, after the constants
const SVG_FILTERS = {
  id: 'waveBlur',
  stdDeviation: 0.5  // Adjust this value to control blur amount
};

// Add this helper function near the top of the file
const getFontSizeForDegree = (degree, nodeRadius) => {
  // Special case for ♭III+ which needs to be smaller
  if (degree === '♭III+') {
    return nodeRadius * 0.9;
  }
  return nodeRadius * 1.25;
};

const DiagramSVG = forwardRef(({
  svgRef,
  dimensions,
  isVertical,
  showBounds,
  theme,
  title,
  titleAreaRef,
  unit,
  stringSizeBase,
  mainGroupTransform,
  stringLinePositions,
  strings,
  isInverted,
  selectedStrings,
  tuning,
  selectedFrets,
  intersections,
  handleStringMouseDown,
  handleStringMouseEnter,
  handleStringMouseLeave,
  handleStringTouchStart,
  handleStringTouchMove,
  isDraggingStrings,
  nodeRadius,
  formatNoteSymbol,
  useFlats,
  selectedKey,
  selectedScale,
  SCALES,
  isNoteInScale,
  nodeVisibility,
  lines,
  isFret0Border,
  fret0Width,
  regularFretWidth,
  verticalCenterOffset,
  getLineWidth,
  isFretless,
  nutScale,
  rightScale,
  visibleFrets,
  handleFretMouseDown,
  handleFretMouseEnter,
  handleFretMouseLeave,
  handleFretTouchStart,
  handleFretTouchMove,
  isDraggingFrets,
  showInlays,
  showNodes,
  lerp,
  orientationProgress,
  firstFret,
  firstFretOffset,
  lastFret,
  lastFretOffset,
  isInfiniteFrets,
  scrollPosition,
  nodeAnimationStates,
  getNoteAtFret,
  NOTES,
  NOTE_TO_INDEX,
  accidentalStyle,
  colorMode,
  nodeDisplayMode,
  scaleRoot,
  getIntervalIndex,
  NOTE_COLORS,
  getNoteColor,
  noteColors,
  rootIndicator,
  clickedNode,
  nodeTransform,
  scaleDisplayMode,
  SCALE_DISPLAY_MODES,
  activeIntervals,
  selectedInterval,
  isDarkMode,
  getNodeOpacity,
  nodeTextMode,
  NODE_TEXT_MODES,
  getLuminance,
  isFilteredInterval,
  getDisplayIntervalName,
  handleNodeMouseDown,
  handleNodeMouseEnter,
  handleNodeMouseLeave,
  handleNodeClick,
  handleNodeTouchStart,
  handleNodeTouchMove,
  handleNodeTouchEnd,
  handleBackgroundStart,
  handleBackgroundMouseMove, 
  handleBackgroundTouchMove,
  isDraggingNodes,
  bottomStringThickness,
  longPressActive,
  endLongPress,
  longPressTimer,
  endBackgroundLongPress,
  backgroundLongPressActive,
  handleBackgroundMove,
  isTuningMode = false,
  handleTuningMouseDown,
  handleTuningTouchStart,
  activeTuningString,
  PADDING,
  scaledHeight,
  toggleLabelFiltering,
  clickMode,
  hiddenNodes,
}, ref) => {

  // Memoize the wave path calculation
  const memoizedWavePath = React.useMemo(() => {
    return (verticalX, verticalY, verticalX2, verticalY2, index) => {
      const length = Math.sqrt(Math.pow(verticalX2 - verticalX, 2) + Math.pow(verticalY2 - verticalY, 2));
      const angle = Math.atan2(verticalY2 - verticalY, verticalX2 - verticalX);
      
      // Get the note being played (if clickedNode exists)
      let playedNote;
      if (clickedNode) {
        const [stringIdx, fret] = clickedNode.split('-').map(Number);
        if (stringIdx === index) {
          const rootNote = isInverted 
            ? tuning[stringIdx] 
            : tuning[tuning.length - 1 - stringIdx];
          playedNote = getNoteAtFret(rootNote, fret, useFlats);
          
          // Log the played note and its frequency
          console.log(`Played Note: ${playedNote}`);
          console.log(`Note Frequency: ${getNoteFrequency(playedNote)} Hz`);
        }
      }
      
      // Use the played note's frequency if available, otherwise use the open string
      const stringNote = playedNote || (isInverted 
        ? tuning[index] 
        : tuning[tuning.length - 1 - index]);
      
      // Calculate frequency and adjust for visualization
      const freq = getNoteFrequency(stringNote);
      const visualFreq = 440 / freq * 0.02; // Scale frequency for visual appeal
      
      // Log the wave frequency
      if (clickedNode) {
        const [stringIdx] = clickedNode.split('-').map(Number);
        if (stringIdx === index) {
          console.log(`Wave Visual Frequency: ${visualFreq}`);
        }
      }
      
      // Create wave path with time parameter
      const createWavePath = (t) => {
        const points = [];
        // Scale amplitude based on string index
        const amplitude = BASE_WAVE_AMPLITUDE + (index * stringSizeBase * 0.4);
        
        for (let i = 0; i <= length; i += 20) {
          const progress = i / length;
          const x = verticalX + (verticalX2 - verticalX) * progress;
          const y = verticalY + (verticalY2 - verticalY) * progress;
          
          const phase = t * Math.PI * 32;
          const wave = Math.sin(progress * Math.PI * 2 / visualFreq + phase) * amplitude;
          const perpX = -Math.sin(angle) * wave;
          const perpY = Math.cos(angle) * wave;
          
          points.push(`${x + perpX} ${y + perpY}`);
        }
        
        points.push(`${verticalX2} ${verticalY2}`);
        return `M ${points.join(' L ')}`;
      };

      // Keep 60 steps for animation compatibility
      const numSteps = 60;
      return Array.from({ length: numSteps + 1 }, (_, i) => 
        createWavePath(i / numSteps)
      ).join(';');
    };
  }, [isInverted, tuning, useFlats, clickedNode, stringSizeBase, getNoteAtFret]);
  // Update the clickedNode effect
  useLayoutEffect(() => {
    if (clickedNode) {
      const [stringIndex] = clickedNode.split('-');
      const parsedIndex = parseInt(stringIndex, 10);
      const pathElement = svgRef.current?.querySelector(`#wave-anim-${parsedIndex}`);
      const opacityElement = pathElement?.parentElement?.querySelector('[attributeName="opacity"]');
      
      if (pathElement && opacityElement) {
        try {
          pathElement.beginElement();
          opacityElement.beginElement();
        } catch (e) {
          console.warn('Failed to start wave animation:', e);
        }
      }
    }
  }, [clickedNode, svgRef]);
  
  return (
    <svg
      ref={ref}
      viewBox={`${-PADDING.left} ${-PADDING.top - PADDING.title} ${
        dimensions.width + PADDING.left + PADDING.right
      } ${dimensions.height + PADDING.top + PADDING.bottom + PADDING.title}`}
      className="w-full h-full flex-1"
      preserveAspectRatio={isVertical ? "xMidYMid meet" : "xMidYMid meet"}
      style={{ 
        touchAction: 'none',
        display: 'block',
        transform: `scale(${scaledHeight})`,
        transformOrigin: 'top center'
      }}
      onMouseDown={handleBackgroundStart}
      onTouchStart={handleBackgroundStart}
      onMouseMove={handleBackgroundMove}
      onTouchMove={handleBackgroundMove}
      onMouseUp={endBackgroundLongPress}
      onTouchEnd={endBackgroundLongPress}
      onMouseLeave={endBackgroundLongPress}
      onTouchCancel={endBackgroundLongPress}
    >
      <defs>
        <filter id={SVG_FILTERS.id}>
          <feGaussianBlur in="SourceGraphic" stdDeviation={SVG_FILTERS.stdDeviation} />
        </filter>
      </defs>
      {showBounds && (
        <g className="padding-visualization">
          {/* Title padding area */}
          <rect
            x={-PADDING.left}
            y={-PADDING.top - PADDING.title}
            width={dimensions.width + PADDING.left + PADDING.right}
            height={PADDING.title}
            fill="rgba(255, 0, 0, 0.25)"
          />
          {/* Top padding area */}
          <rect
            x={-PADDING.left}
            y={-PADDING.top}
            width={dimensions.width + PADDING.left + PADDING.right}
            height={PADDING.top}
            fill="rgba(0, 255, 0, 0.25)"
          />
          {/* Left padding area */}
          <rect
            x={-PADDING.left}
            y={-PADDING.top}
            width={PADDING.left}
            height={dimensions.height + PADDING.top + PADDING.bottom}
            fill="rgba(0, 0, 255, 0.25)"
          />
          {/* Right padding area */}
          <rect
            x={dimensions.width}
            y={-PADDING.top}
            width={PADDING.right}
            height={dimensions.height + PADDING.top + PADDING.bottom}
            fill="rgba(255, 255, 0, 0.25)"
          />
          {/* Bottom padding area */}
          <rect
            x={-PADDING.left}
            y={dimensions.height}
            width={dimensions.width + PADDING.left + PADDING.right}
            height={PADDING.bottom}
            fill="rgba(128, 0, 128, 0.25)"
          />
        </g>
      )}
      <g>
        <rect
          ref={node => {
            if (node) {
              const rectHeight = unit * 2; // Fixed height of 2 units
              const titleCenterY = -PADDING.top - PADDING.title / 2;
              const rectY = titleCenterY - rectHeight / 2;
              
              node.setAttribute('y', rectY);
              node.setAttribute('height', rectHeight);
            }
          }}
          fill="none"
          stroke={theme.stringColor}
          strokeWidth={stringSizeBase * 3}
          rx={unit * 0.5}
          ry={unit * 0.5}
        />
        <text
          ref={node => {
            if (node) {
              const bbox = node.getBBox();
              const paddingX = unit; // Remove conditional, always use default padding
              
              const titleCenterY = -PADDING.top - PADDING.title / 2;
              const textY = titleCenterY;
              node.setAttribute('y', textY);
              
              const rectWidth = bbox.width + (paddingX * 2);
              const rectX = -PADDING.left + (dimensions.width + PADDING.left + PADDING.right) / 2 - (rectWidth / 2);
              
              const rectNode = node.previousSibling;
              if (rectNode) {
                rectNode.setAttribute('x', rectX);
                rectNode.setAttribute('width', rectWidth);

                // Get the actual screen coordinates of the rect
                const rect = rectNode.getBoundingClientRect();
                
                // Set the ref with the screen coordinates
                if (titleAreaRef) {
                  titleAreaRef.current = {
                    top: rect.top,
                    height: rect.height,
                    center: rect.top + (rect.height / 2)
                  };
                }
              }
            }
          }}
          x={-PADDING.left + (dimensions.width + PADDING.left + PADDING.right) / 2}
          textAnchor="middle"
          alignmentBaseline="central"
          fill={theme.stringColor}
          fontSize={unit * 1.25}
          style={{
            fontWeight: 'bold',
            opacity: 1
          }}
        >
          {title}
        </text>
      </g>

          <g transform={`${mainGroupTransform} ${isVertical ? `translate(${-unit * 0.75}, 0)` : ''}`}>
            {/* Tuning mode background overlay */}
            {isTuningMode && (
              <rect
                x={-dimensions.width}  // Extend well beyond left edge
                y={-dimensions.height} // Extend well beyond top edge
                width={dimensions.width * 3}  // Make sure it's wide enough
                height={dimensions.height * 3} // Make sure it's tall enough
                fill={theme.stringColor}
                opacity={0.25}
              />
            )}

            {/* Inlay toggle area*/}
            {(() => {
              const firstString = 0;
              const labelOffset = nodeRadius * 3;
              
              return (
                <rect
                  x={lerp(
                    firstFret + firstFretOffset,
                    dimensions.height - firstString + labelOffset - verticalCenterOffset - nodeRadius * 1.5,
                    orientationProgress
                  )}
                  y={lerp(
                    -PADDING.top * 0.75 - nodeRadius * 1.5,
                    firstString,
                    orientationProgress
                  )}
                  width={lerp(
                    lastFret + lastFretOffset - (firstFret + firstFretOffset),
                    nodeRadius * 3,
                    orientationProgress
                  )}
                  height={lerp(
                    nodeRadius * 3,
                    dimensions.height,
                    orientationProgress
                  )}
                  fill="transparent"
                />
              );
            })()}

            {/* String Labels */}
            {stringLinePositions.map((y, index) => {
              // Calculate horizontal and vertical positions with proper centering
              const horizontalOffset = -PADDING.left * 0.5;
              const verticalOffset = dimensions.height + PADDING.bottom * 0.5;
              
              const verticalX = lerp(
                horizontalOffset, 
                dimensions.height - y - verticalCenterOffset,
                orientationProgress
              );
              const verticalY = lerp(
                y,
                verticalOffset,
                orientationProgress
              );
              
              const stringNumber = isInverted ? strings - index : index + 1;
              const isSelected = selectedStrings.has(stringNumber);
              
              // Calculate opacity based on selection state
              const opacity = selectedStrings.size > 0 ? (isSelected ? 1 : 0.5) : 1;
              
              const tuningIndex = isInverted ? index : strings - 1 - index;
              const rawStringNote = tuning[tuningIndex] || '';
              const noteMatch = rawStringNote.match(/([A-G][#b]?)(\d+)?/);
              const noteWithoutOctave = noteMatch ? noteMatch[1] : '';
              
              // Convert the note to the correct accidental style before formatting
              const convertedNote = (() => {
                const noteIndex = NOTE_TO_INDEX[noteWithoutOctave];
                if (noteIndex === undefined) return noteWithoutOctave;
                
                const note = NOTES[noteIndex];
                if (!Array.isArray(note)) return noteWithoutOctave;
                
                // Use the appropriate spelling based on accidentalStyle
                switch (accidentalStyle) {
                  case 'sharp':
                    return note[0]; // Use sharp spelling
                  case 'flat':
                    return note[1]; // Use flat spelling
                  case 'both':
                    return noteWithoutOctave; // Keep original for 'both' style
                  default:
                    return noteWithoutOctave;
                }
              })();
              
              // Then use convertedNote instead of noteWithoutOctave
              const stringNote = (() => {
                if (selectedKey && selectedScale) {
                  return formatNoteSymbol(convertedNote, accidentalStyle, selectedKey, selectedScale);
                }

                if (!Array.isArray(NOTES[NOTE_TO_INDEX[convertedNote]])) {
                  return convertedNote;
                }
                
                const [sharpSpelling, flatSpelling] = NOTES[NOTE_TO_INDEX[convertedNote]];
                switch (accidentalStyle) {
                  case 'sharp':
                    return formatAccidental(sharpSpelling);
                  case 'flat':
                    return formatAccidental(flatSpelling);
                  case 'both':
                    return `${formatAccidental(sharpSpelling)}/${formatAccidental(flatSpelling)}`;
                  case 'none':
                    return convertedNote;
                  default:
                    return formatAccidental(sharpSpelling);
                }
              })();
              
              // Update combined label logic to include isVertical check
              const showCombinedLabel = isVertical ||
                nodeDisplayMode === 'intervals' || 
                (selectedFrets.size > 0 && !selectedFrets.has(0)) ||
                isTuningMode ||
                nodeTextMode === NODE_TEXT_MODES.BLANK ||
                nodeTextMode === NODE_TEXT_MODES.NUMERALS;
              
              // Only check for visible fret 0 node if we're not showing combined label
              const hasVisibleFret0Node = !showCombinedLabel && intersections.some(intersection => {
                if (intersection.stringIndex !== index || intersection.currentFret !== 0) {
                  return false;
                }
                
                // Check if the note is in the scale (if a scale is selected)
                const rootNote = isInverted 
                  ? tuning[index] 
                  : tuning[tuning.length - 1 - index];
                const noteAtFret = getNoteAtFret(rootNote, 0, useFlats);
                
                const isInScale = selectedKey && selectedScale && SCALES[selectedScale]
                  ? isNoteInScale(noteAtFret, selectedKey, SCALES[selectedScale])
                  : selectedScale === 'Chromatic';
                
                return nodeVisibility.show && isInScale;
              });
              
              return (
                <g 
                  key={`label-${index}`}
                  onMouseDown={(e) => handleStringMouseDown(stringNumber, e)}
                  onMouseEnter={() => handleStringMouseEnter(stringNumber)}
                  onTouchStart={(e) => handleStringTouchStart(stringNumber, e)}
                  onTouchMove={handleStringTouchMove}
                  onMouseLeave={(e) => handleStringMouseLeave(stringNumber, e)}
                  style={{ 
                    cursor: isDraggingStrings ? 'pointer' : 'default',
                    touchAction: 'none',
                    opacity: opacity  // Apply the opacity here
                  }}
                >
                  <rect
                    x={verticalX - nodeRadius * (isVertical ? 1.25 : 2)}
                    y={verticalY - nodeRadius * 1}
                    width={nodeRadius * (isVertical ? 2.5 : 4)}
                    height={nodeRadius * 2}
                    fill="transparent"
                  />
                  <text
                    x={verticalX}
                    y={verticalY}
                    textAnchor="middle"
                    alignmentBaseline="central"
                    dominantBaseline="central"
                    fill={theme.stringColor}
                    fontSize={nodeRadius * 1} // Always small for string labels
                    style={{
                      fontWeight: isSelected ? 'bold' : 'normal',
                      pointerEvents: 'none'
                    }}
                  >
                    {stringNumber}{(!hasVisibleFret0Node || showCombinedLabel) && stringNote}
                  </text>
                </g>
              );
            })}

            {/* String  */}
            {stringLinePositions.map((y, index) => {
              const nutBorderExtra = isFret0Border ? 0 : regularFretWidth / 2;
              
              const x1 = firstFret + firstFretOffset - nutBorderExtra;
              const x2 = lastFret + lastFretOffset;

              const verticalX = lerp(x1, dimensions.height - y - verticalCenterOffset, orientationProgress);
              const verticalY = lerp(y, x1, orientationProgress);
              const verticalX2 = lerp(x2, dimensions.height - y - verticalCenterOffset, orientationProgress);
              const verticalY2 = lerp(y, x2, orientationProgress);

              // Create path data for both straight line and wave
              const straightPath = `M ${verticalX} ${verticalY} L ${verticalX2} ${verticalY2}`;
              const wavePath = memoizedWavePath(verticalX, verticalY, verticalX2, verticalY2, index);

              // Calculate opacity based on tuning mode and active string
              const stringOpacity = (isTuningMode && activeTuningString !== null) 
                ? (activeTuningString === index ? 1 : 0.25)  // Only dim others when actively tuning
                : 1;  // Normal opacity otherwise

              return (
                <g key={`h-${index}`}>
                  {/* Base straight line */}
                  <path
                    d={straightPath}
                    stroke={theme.stringColor}
                    strokeWidth={getLineWidth(index, strings)}
                    fill="none"
                    opacity={stringOpacity}  // Apply the calculated opacity
                  />
                  {/* Animated wave overlay */}
                  <path
                    d={straightPath}
                    stroke={theme.stringColor}
                    strokeWidth={getLineWidth(index, strings)}
                    fill="none"
                    filter={`url(#${SVG_FILTERS.id})`}
                  >
                    <animate
                      id={`wave-anim-${index}`}
                      attributeName="d"
                      dur={`${WAVE_DURATION}s`}
                      values={wavePath}
                      keyTimes={Array.from({ length: 61 }, (_, i) => i / 60).join(';')}
                      calcMode="spline"
                      keySplines={Array(60).fill("0.4 0 0.2 1").join(';')}
                      repeatCount={WAVE_REPEAT_COUNT}
                      begin="indefinite"
                    />
                    <animate
                      attributeName="opacity"
                      dur={`${WAVE_DURATION}s`}
                      values="1;0"
                      begin="indefinite"
                      fill="freeze"
                    />
                  </path>
                </g>
              );
            })}
            
            {/* Update fret lines */}
            {lines.map((x, index) => {
              const stringSpacing = nodeRadius * 3;
              const firstString = 0;
              const lastString = isVertical
                ? stringSpacing * (Math.max(2, strings) - 1)
                : dimensions.height;
              
              const verticalCenterOffset = isVertical
                ? (dimensions.height - lastString) / 2
                : 0; 
              
              // Calculate width for fret 0 and last fret
              let fretWidth = regularFretWidth;
              let offsetX = 0;

              if (!isInfiniteFrets) {
                const fretNumber = visibleFrets[index];
                if (index === 0) {
                  // Only use fret0Width when it's actually fret 0
                  const fullWidth = fretNumber === 0 ? fret0Width : regularFretWidth;
                  fretWidth = fullWidth * nutScale;
                  // Offset by half the difference between full and scaled width
                  offsetX = -(fullWidth - fretWidth) / 2;
                } else if (index === lines.length - 1) {
                  // Scale last fret width from regularFretWidth to 0 based on rightScale
                  fretWidth = regularFretWidth * rightScale;
                  // Offset by half the difference between full and scaled width
                  offsetX = (regularFretWidth - fretWidth) / 2;
                }
              }

              // Calculate opacity based on position and scale, starting fade at 75% of original scale
              let opacity = isFretless 
                ? (index === 0 || index === lines.length - 1) ? 1 : 0.5  // Full opacity for nut/bridge in fretless mode
                : 1;
              if (!isInfiniteFrets) {
                if (index === 0) {
                  // Map nutScale from 0.75-1 range to 0-1 range for opacity
                  opacity *= nutScale >= 0.75 ? 1 : (nutScale / 0.75);
                } else if (index === lines.length - 1) {
                  // Map rightScale from 0.75-1 range to 0-1 range for opacity
                  opacity *= rightScale >= 0.75 ? 1 : (rightScale / 0.75);
                }
              }

              const verticalX = lerp(x + offsetX, dimensions.height - firstString - verticalCenterOffset, orientationProgress);
              const verticalY = lerp(firstString, x + offsetX, orientationProgress);
              const verticalX2 = lerp(x + offsetX, dimensions.height - lastString - verticalCenterOffset, orientationProgress);
              const verticalY2 = lerp(lastString, x + offsetX, orientationProgress);
              
              const fretNumber = visibleFrets[index];
              const isSolidFret = fretNumber === 0 || fretNumber === 24;
              
              return (
                <line
                  key={`v-${index}`}
                  x1={verticalX}
                  y1={verticalY}
                  x2={verticalX2}
                  y2={verticalY2}
                  stroke={theme.fretColor}
                  strokeWidth={fretWidth}
                  opacity={opacity}
                  strokeDasharray={isFretless && !isSolidFret ? "4 2" : "none"}
                />
              );
            })}

            {/* Add this section before the nodes rendering */}
            {/* Fret Deselect Blocker */}
            {selectedFrets.size > 0 && lines.map((x, index) => {
              const fretNumber = visibleFrets[index];
              if (!selectedFrets.has(fretNumber)) return null;

              // Get the previous fret position
              const prevX = lines[index - 1] || (x - regularFretWidth);
              
              // Calculate width between frets
              const width = x - prevX;
              
              // Get string positions for height
              const firstString = stringLinePositions[0];
              const lastString = stringLinePositions[stringLinePositions.length - 1];
              const height = lastString - firstString;

              // Calculate vertical mode positions
              const verticalX = lerp(
                prevX,
                dimensions.height - lastString - verticalCenterOffset,
                orientationProgress
              );
              const verticalY = lerp(
                firstString,
                prevX,
                orientationProgress
              );

              return (
                <rect
                  key={`fret-highlight-${fretNumber}`}
                  x={verticalX}
                  y={verticalY}
                  width={lerp(width, height, orientationProgress)}
                  height={lerp(height, width, orientationProgress)}
                  fill="transparent"
                  opacity={0.2}
                />
              );
            })}

            {/* Update nodes */}
            {nodeVisibility.mounted && 
              intersections.map((point) => {
                const { stringIndex, fretIndex, currentFret } = point;
                const noteKey = `${stringIndex}-${currentFret}`;
                const animationState = nodeAnimationStates.get(noteKey);
                
                const rootNote = isInverted 
                  ? tuning[stringIndex] 
                  : tuning[tuning.length - 1 - stringIndex];
                
                const noteAtFret = !isInfiniteFrets ? getNoteAtFret(rootNote, currentFret, useFlats) : '';
                
                // Add string display mode check
                const isSelectedString = selectedStrings.has(
                  isInverted ? strings - stringIndex : stringIndex + 1
                );
                const showForStringMode = selectedStrings.size === 0 || isSelectedString;
                
                // If there are selected strings and this isn't one of them, don't render the node
                if (selectedStrings.size > 0 && !isSelectedString) return null;
                
                // Keep original scale filtering logic, don't override with isSelectedString
                const isInScale = selectedKey && selectedScale && SCALES[selectedScale]
                  ? isNoteInScale(noteAtFret, selectedKey, SCALES[selectedScale])
                  : selectedScale === 'Chromatic';
                
                // Update node scale calculation
                const nodeScale = animationState?.show || (isInScale && nodeVisibility.show) ? 1 : 0;

                if (!isInScale && !animationState) return null;

                const verticalX = lerp(
                  point.x,
                  dimensions.height - point.y - verticalCenterOffset,
                  orientationProgress
                );
                const verticalY = lerp(
                  point.y,
                  point.x,
                  orientationProgress
                );
                
                // Update the fill color calculation
                const nodeColor = (() => {
                  if (colorMode === 'Rainbow' && nodeDisplayMode === 'intervals' && scaleRoot) {
                    // Get the interval index relative to the scale root
                    const intervalIndex = getIntervalIndex(noteAtFret, scaleRoot);
                    if (intervalIndex !== null) {
                      return NOTE_COLORS.rainbow[intervalIndex];
                    }
                  }
                  // Pass nodeDisplayMode and nodeTextMode to getNoteColor
                  return getNoteColor(noteAtFret, colorMode, theme, scaleRoot, nodeDisplayMode, nodeTextMode) || 
                         noteColors[noteAtFret] || 
                         theme.nodeColor;
                })();
                
                // Add fret selection check
                const isSelectedFret = selectedFrets.has(point.currentFret);
                const showForFretMode = selectedFrets.size === 0 || isSelectedFret;

                // If there are selections and this node isn't selected, don't render it
                if ((selectedStrings.size > 0 && !isSelectedString) || 
                    (selectedFrets.size > 0 && !isSelectedFret)) {
                  return null;
                }

                return (
                  <g 
                    key={`node-${stringIndex}-${currentFret}`}
                    style={{ cursor: 'pointer' }}
                  >
                    {/* Root indicator - now first so it appears behind */}
                    {noteAtFret === scaleRoot && rootIndicator !== 'none' && (
                      <>
                        {rootIndicator === 'circle' && (
                          <circle
                            cx={verticalX}
                            cy={verticalY}
                            r={point.radius * 1.25}
                            fill={theme.diagramBg}
                            stroke={theme.nodeColor}
                            strokeWidth={2}
                            opacity={isTuningMode && activeTuningString !== null && activeTuningString !== stringIndex ? 0 : 1}
                          />
                        )}
                        {rootIndicator === 'square' && (
                          <rect
                            x={verticalX - point.radius * 1.25}
                            y={verticalY - point.radius * 1.25}
                            width={point.radius * 2.5}
                            height={point.radius * 2.5}
                            fill={theme.diagramBg}
                            stroke={theme.nodeColor}
                            strokeWidth={2}
                            opacity={isTuningMode && activeTuningString !== null && activeTuningString !== stringIndex ? 0 : 1}
                          />
                        )}
                      </>
                    )}

                    {/* Main node circle */}
                    <g
                      transform={`translate(${verticalX}, ${verticalY}) scale(${clickedNode === `${stringIndex}-${currentFret}` ? nodeTransform.scale : nodeScale})`}
                    >
                      <circle
                        cx={0}
                        cy={0}
                        r={point.radius + (colorMode === 'Piano' ? 0.5 : 0)}
                        fill={(() => {
                          // Get base opacity for this node
                          let baseOpacity = point.opacity;
                          if (nodeDisplayMode === 'intervals' && scaleRoot) {
                            const intervalIndex = getIntervalIndex(noteAtFret, scaleRoot);
                            if (intervalIndex !== null) {
                              baseOpacity *= getNodeOpacity(
                                intervalIndex,
                                scaleDisplayMode,
                                activeIntervals,
                                selectedInterval
                              );
                            }
                          }

                          // If node is faded (inactive), use theme color instead of custom colors
                          if (baseOpacity < 1) {
                            return theme.nodeColor;
                          }

                          // Otherwise use the existing color logic
                          if (colorMode === 'Piano') {
                            return Array.isArray(NOTES[NOTE_TO_INDEX[noteAtFret]])
                              ? (isDarkMode 
                                  ? theme.diagramBg  // Black keys
                                  : theme.nodeColor)  // White keys
                              : (isDarkMode 
                                  ? theme.nodeColor  // Dark mode: border only on black keys
                                  : theme.diagramBg); // Light mode: border only on white keys
                          }

                          if (colorMode === 'Rainbow' && nodeDisplayMode === 'intervals' && scaleRoot) {
                            const intervalIndex = getIntervalIndex(noteAtFret, scaleRoot);
                            if (intervalIndex !== null) {
                              return NOTE_COLORS.rainbow[intervalIndex];
                            }
                          }

                          return nodeColor || noteColors[noteAtFret] || theme.nodeColor;
                        })()}
                        stroke={colorMode === 'Piano' 
                          ? (isDarkMode 
                              ? (Array.isArray(NOTES[NOTE_TO_INDEX[noteAtFret]]) ? '#777' : 'none')
                              : (Array.isArray(NOTES[NOTE_TO_INDEX[noteAtFret]]) ? 'none' : '#000'))
                          : 'none'}
                        strokeWidth={1}
                        opacity={(() => {
                          // Check if node is hidden in edit mode
                          const nodeKey = `${stringIndex}-${currentFret}`;
                          if (clickMode === 'edit' && hiddenNodes.has(nodeKey)) {
                              return 0;
                          }

                          // Check tuning mode first
                          if (isTuningMode && activeTuningString !== null) {
                              if (activeTuningString !== stringIndex) {
                                  return 0;
                              }
                          }

                          // If this is a selected string, show at full opacity
                          if (showForStringMode && showForFretMode) {
                              // Get base opacity
                              let baseOpacity = point.opacity;
                              
                              // Only apply interval-based opacity in interval display mode
                              if (nodeDisplayMode === 'intervals' && scaleRoot) {
                                  const intervalIndex = getIntervalIndex(noteAtFret, scaleRoot);
                                  if (intervalIndex !== null) {
                                      baseOpacity *= getNodeOpacity(
                                          intervalIndex,
                                          scaleDisplayMode,
                                          activeIntervals,
                                          selectedInterval
                                      );
                                  }
                              }
                              
                              return baseOpacity;
                          }
                          
                          // If not selected, use minimal opacity
                          return 0.15;
                        })()}
                        style={{
                          transformOrigin: `${verticalX}px ${verticalY}px`,
                          transition: 'transform 150ms ease-out, opacity 200ms ease-out',
                        }}
                      />
                    </g>

                    {/* Note text */}
                    {showNodes && !isInfiniteFrets && (
                      (!isTuningMode || activeTuningString === null || activeTuningString === stringIndex) && (
                        nodeTextMode !== NODE_TEXT_MODES.BLANK && (
                          <text
                            x={verticalX}
                            y={verticalY}
                            textAnchor="middle"
                            alignmentBaseline="central"
                            dominantBaseline="central"
                            fill={(() => {
                              // For root note in any mode, calculate based on red color
                              if ((colorMode === 'Default' || colorMode === 'Piano') && noteAtFret === scaleRoot) {
                                return getLuminance('#FF0000') ? '#FFFFFF' : '#000000';
                              }
                              
                              if (colorMode === 'Piano') {
                                return Array.isArray(NOTES[NOTE_TO_INDEX[noteAtFret]]) 
                                  ? '#FFFFFF'  // White text for black keys
                                  : '#000000'; // Black text for white keys
                              }
                              
                              // For Default mode, use contrasting color based on theme
                              if (colorMode === 'Default') {
                                return theme.diagramBg;
                              }
                              
                              // For Rainbow or custom colors, use luminance
                              return nodeColor && getLuminance(nodeColor) ? '#FFFFFF' : '#000000';
                            })()}
                            fontSize={(() => {
                              // Special handling for numerals mode with ♭III+
                              if (nodeTextMode === NODE_TEXT_MODES.NUMERALS && selectedKey && selectedScale) {
                                const keyIndex = NOTE_TO_INDEX[selectedKey];
                                const noteIndex = NOTE_TO_INDEX[noteAtFret];
                                
                                if (keyIndex !== undefined && noteIndex !== undefined) {
                                  const interval = (noteIndex - keyIndex + 12) % 12;
                                  const scaleIntervals = SCALES[selectedScale];
                                  const scaleDegree = scaleIntervals.indexOf(interval) + 1;
                                  
                                  const scaleDegrees = SCALE_DEGREES[selectedScale];
                                  if (scaleDegrees && scaleDegree > 0) {
                                    return getFontSizeForDegree(scaleDegrees[scaleDegree - 1], point.radius);
                                  }
                                }
                              }
                              return point.radius * 1.25;
                            })()}
                            style={{
                              transform: `scale(${clickedNode === `${stringIndex}-${currentFret}` ? nodeTransform.scale : nodeScale})`,
                              transformOrigin: `${verticalX}px ${verticalY}px`,
                              userSelect: 'none',
                              fontWeight: 'bold',
                              opacity: (() => {
                                // Check tuning mode first
                                if (isTuningMode && activeTuningString !== null) {
                                  if (activeTuningString !== stringIndex) {
                                    return 0;
                                  }
                                }
                                // Apply scaling opacity
                                // Apply opacity based on fret position
                                if (fretIndex === 1) {  // First visible fret
                                  return nutScale >= 0.75 ? 1 : (nutScale - 0.5) / 0.25;
                                } else if (fretIndex === lines.length - 1) {  // Last visible fret
                                  return rightScale >= 0.75 ? 1 : (rightScale - 0.5) / 0.25;
                                }
                              })(),
                            }}
                          >
                            {(() => {
                              // If we're in interval display mode AND numerals mode
                              if (nodeDisplayMode === 'intervals' && nodeTextMode === NODE_TEXT_MODES.NUMERALS && scaleRoot) {
                                // For the root note, show the roman numeral based on the key
                                if (noteAtFret === scaleRoot) {
                                  // Get the interval relative to the key
                                  const keyIndex = NOTE_TO_INDEX[selectedKey];
                                  const noteIndex = NOTE_TO_INDEX[noteAtFret];
                                  
                                  if (keyIndex !== undefined && noteIndex !== undefined) {
                                    const interval = (noteIndex - keyIndex + 12) % 12;
                                    const scaleIntervals = SCALES[selectedScale];
                                    const scaleDegree = scaleIntervals.indexOf(interval) + 1;
                                    
                                    // Get the scale degrees for the current scale type
                                    const scaleDegrees = SCALE_DEGREES[selectedScale];
                                    if (scaleDegrees && scaleDegree > 0) {
                                      return scaleDegrees[scaleDegree - 1];
                                    }
                                  }
                                  return '';
                                }

                                // For non-root notes, show the interval number
                                const intervalIndex = getIntervalIndex(noteAtFret, scaleRoot);
                                if (intervalIndex !== null) {
                                  if (scaleDisplayMode === SCALE_DISPLAY_MODES.TRIADS || 
                                      scaleDisplayMode === SCALE_DISPLAY_MODES.TETRACHORDS ||
                                      scaleDisplayMode === SCALE_DISPLAY_MODES.FIFTHS) {
                                    if (!isFilteredInterval(intervalIndex, activeIntervals, scaleDisplayMode)) {
                                      return '';
                                    }
                                    if (scaleDisplayMode === SCALE_DISPLAY_MODES.FIFTHS) {
                                      if (intervalIndex !== 0 && intervalIndex !== 6 && intervalIndex !== 7 && intervalIndex !== 8) {
                                        return '';
                                      }
                                    }
                                  }
                                  return getDisplayIntervalName(intervalIndex, activeIntervals) || '';
                                }
                                return '';
                              }

                              // Original numeral mode logic for when intervals are not being displayed
                              if (nodeTextMode === NODE_TEXT_MODES.NUMERALS && selectedKey && selectedScale) {
                                // First check if the note is in the scale
                                if (isNoteInScale(noteAtFret, selectedKey, SCALES[selectedScale])) {
                                  // Get the interval relative to the key (not the scale root)
                                  const keyIndex = NOTE_TO_INDEX[selectedKey];
                                  const noteIndex = NOTE_TO_INDEX[noteAtFret];
                                  
                                  if (keyIndex !== undefined && noteIndex !== undefined) {
                                    // Calculate the scale degree (1-based)
                                    const interval = (noteIndex - keyIndex + 12) % 12;
                                    const scaleIntervals = SCALES[selectedScale];
                                    const scaleDegree = scaleIntervals.indexOf(interval) + 1;
                                    
                                    // Get the scale degrees for the current scale type
                                    const scaleDegrees = SCALE_DEGREES[selectedScale];
                                    if (scaleDegrees && scaleDegree > 0) {
                                      return scaleDegrees[scaleDegree - 1];
                                    }
                                  }
                                }
                                return '';
                              }

                              // Original note display logic
                              if (nodeDisplayMode === 'intervals' && scaleRoot) {
                                if (noteAtFret === scaleRoot) {
                                  return formatNoteSymbol(noteAtFret, accidentalStyle, selectedKey, selectedScale);
                                }
                                const intervalIndex = getIntervalIndex(noteAtFret, scaleRoot);
                                if (intervalIndex !== null) {
                                  if (scaleDisplayMode === SCALE_DISPLAY_MODES.TRIADS || 
                                      scaleDisplayMode === SCALE_DISPLAY_MODES.TETRACHORDS ||
                                      scaleDisplayMode === SCALE_DISPLAY_MODES.FIFTHS) {
                                    if (!isFilteredInterval(intervalIndex, activeIntervals, scaleDisplayMode)) {
                                      return '';
                                    }
                                    if (scaleDisplayMode === SCALE_DISPLAY_MODES.FIFTHS) {
                                      if (intervalIndex !== 0 && intervalIndex !== 6 && intervalIndex !== 7 && intervalIndex !== 8) {
                                        return '';
                                      }
                                    }
                                  }
                                  return getDisplayIntervalName(intervalIndex, activeIntervals) || '';
                                }
                                return '';
                              }
                              
                              if (accidentalStyle === 'both' && Array.isArray(NOTES[NOTE_TO_INDEX[noteAtFret]])) {
                                const formattedNote = formatNoteSymbol(noteAtFret, 'both', selectedKey, selectedScale);
                                return (
                                  <tspan>
                                    <tspan x={verticalX} dy="-0.4em">{formattedNote.sharp}</tspan>
                                    <tspan x={verticalX} dy="0.8em">{formattedNote.flat}</tspan>
                                  </tspan>
                                );
                              }
                              return formatNoteSymbol(noteAtFret, accidentalStyle, selectedKey, selectedScale);
                            })()}
                          </text>
                        )
                      )
                    )}

                    {/* Replace circle overlay with rectangle */}
                    <rect
                      x={verticalX - point.radius * 1.5}
                      y={verticalY - point.radius * 1.5}
                      width={point.radius * 3}
                      height={point.radius * 3}
                      fill="transparent"
                      style={{
                        cursor: isDraggingNodes ? 'pointer' : 'default',
                        transition: 'opacity 150ms ease-out',
                        touchAction: 'none',
                      }}
                      onClick={(e) => {
                        if (!isDraggingNodes) {
                          handleNodeClick(noteAtFret, stringIndex, currentFret, e);
                        }
                      }}
                      onMouseDown={(e) => handleNodeMouseDown(noteAtFret, stringIndex, currentFret, e)}
                      onMouseEnter={(e) => handleNodeMouseEnter(noteAtFret, stringIndex, currentFret, e)}
                      onMouseLeave={(e) => handleNodeMouseLeave(noteAtFret, stringIndex, currentFret, e)}
                      onTouchStart={(e) => handleNodeTouchStart(noteAtFret, stringIndex, currentFret, e)}
                      onTouchMove={(e) => handleNodeTouchMove(e)}
                      onTouchEnd={handleNodeTouchEnd}
                    />
                  </g>
                );
              })}

            {/* String overlay boxes */}
            {stringLinePositions.map((y, index) => {
              
              // Calculate start and end points of string
              const nutBorderExtra = isFret0Border ? 0 : regularFretWidth / 2;
              const x1 = isVertical 
                ? (firstFret + firstFretOffset - nutBorderExtra)
                : -PADDING.left; // Extend to left edge in horizontal mode
              const x2 = lastFret + lastFretOffset;

              // Calculate positions using the same lerp logic as the strings
              const verticalX = lerp(x1, dimensions.height - y - verticalCenterOffset, orientationProgress);
              const verticalY = lerp(y, x1, orientationProgress);
              const verticalX2 = lerp(x2, dimensions.height - y - verticalCenterOffset, orientationProgress);
              const verticalY2 = lerp(y, x2, orientationProgress);

              // Calculate box dimensions to cover entire string
              const boxSize = nodeRadius * 3;
              
              // Calculate width and height based on orientation
              const width = lerp(
                Math.abs(verticalX2 - verticalX),  // Horizontal width
                boxSize,                           // Vertical width
                orientationProgress
              );
              
              // In vertical mode, extend height to include padding areas (excluding title)
              const verticalHeight = dimensions.height + PADDING.top + PADDING.bottom;
              const height = lerp(
                boxSize,           // Horizontal height
                verticalHeight,    // Vertical height (including padding, excluding title)
                orientationProgress
              );

              // Adjust y position to start from the top of the padding area in vertical mode
              const yPos = lerp(
                Math.min(verticalY, verticalY2) - (isVertical ? 0 : boxSize / 2),  // Horizontal y position
                -PADDING.top,                                                       // Vertical y position (start from top padding)
                orientationProgress
              );

              return (
                <rect
                  key={`string-box-${index}`}
                  x={Math.min(verticalX, verticalX2) - (isVertical ? boxSize / 2 : 0)}
                  y={yPos}
                  width={width}
                  height={height}
                  fill={'transparent'}
                  style={{
                    pointerEvents: isTuningMode ? 'auto' : 'none',
                    cursor: isTuningMode ? 'pointer' : 'default',
                    touchAction: 'none',
                  }}
                  onMouseDown={(e) => handleTuningMouseDown(index, e)}
                  onTouchStart={(e) => handleTuningTouchStart(index, e)}
                />
              );
            })}

            {/* Fret Numbers */}
            {!isInfiniteFrets &&
              lines.map((x, index) => {
                const fretNumber = visibleFrets[index];
                if (fretNumber === undefined || fretNumber < 0 || fretNumber > 24) return null;
                if (index === 0 && fretNumber !== 0) return null;

                const hasInlay = INLAY_POSITIONS.includes(fretNumber);
                
                // Calculate base opacity based on position
                let baseOpacity;
                if (index === 0) {
                  baseOpacity = nutScale;
                } else if (index === 1) {
                  baseOpacity = nutScale;
                } else if (index === lines.length - 1) {
                  baseOpacity = rightScale;
                } else {
                  baseOpacity = 1;
                }

                // Adjust opacity based on selection state
                const finalOpacity = selectedFrets.size > 0
                  ? (selectedFrets.has(fretNumber) ? baseOpacity : baseOpacity * 0.5)
                  : baseOpacity;

                let baseX = x;
                let offset;
                if (fretNumber === 0) {
                  baseX = showNodes 
                    ? x - (fret0Width / 2) - nodeRadius
                    : x;
                } else {
                  const scale = index === 1 ? nutScale : 1;
                  offset = showNodes 
                    ? -(nodeRadius + stringSizeBase * 1) * scale 
                    : -(stringSizeBase * 1) * scale;
                }

                // Get the first string position (thinnest string) for inlays
                const firstString = stringLinePositions[0];
                // Get the last string position (thickest string) for labels
                const lastString = stringLinePositions[stringLinePositions.length - 1];
                
                // Center in the bottom padding area for horizontal mode
                const bottomPaddingCenterY = dimensions.height + (PADDING.bottom / 2) + 
                  (!isVertical && showNodes ? nodeRadius * 0.5  - (bottomStringThickness / 2) : 0);
                
                const labelOffset = nodeRadius * 3;

                // Calculate positions for fret numbers
                const verticalX = lerp(
                  baseX,
                  dimensions.height - lastString - labelOffset - verticalCenterOffset,
                  orientationProgress
                );
                const verticalY = lerp(
                  bottomPaddingCenterY,
                  baseX,
                  orientationProgress
                );

                // Calculate positions for inlays (opposite side)
                const inlayVerticalX = lerp(
                  baseX,
                  dimensions.height - firstString + labelOffset - verticalCenterOffset,
                  orientationProgress
                );
                const inlayVerticalY = lerp(
                  -PADDING.top * 0.75, // Center vertically in top padding (perceived halfway point)
                  baseX,
                  orientationProgress
                );

                // Don't apply position transition for fret 0
                const transform = fretNumber === 0
                  ? ''
                  : `${isVertical ? 'rotate(0)' : ''} translate(${offset * (1 - orientationProgress)}, ${offset * orientationProgress})`;

                const isSelected = selectedFrets.has(fretNumber);

                return (
                  <g key={`fret-${fretNumber}`}>
                    {/* Existing inlay circle */}
                    {showInlays && hasInlay && (
                      <circle
                        cx={inlayVerticalX}
                        cy={inlayVerticalY}
                        r={nodeRadius * 0.25}
                        fill={theme.inlayColor}
                        transform={transform}
                      />
                    )}
                    
                    {/* Add clickable area with the same transform as the text */}
                    <rect
                      x={verticalX - lerp(
                        nodeRadius * 1,           // horizontal x offset
                        nodeRadius * 2,    // vertical x offset
                        orientationProgress
                      )}
                      y={verticalY - lerp(
                        nodeRadius * 1,    // horizontal y offset
                        nodeRadius * 1,           // vertical y offset
                        orientationProgress
                      )}
                      width={lerp(
                        nodeRadius * 2,        // horizontal width
                        nodeRadius * 3.5,      // vertical width
                        orientationProgress
                      )}
                      height={lerp(
                        nodeRadius * 3,      // horizontal height
                        nodeRadius * 2,        // vertical height
                        orientationProgress
                      )}
                      fill="transparent"
                      style={{ 
                        cursor: isDraggingFrets ? 'pointer' : 'default',
                        touchAction: 'none',
                      }}
                      transform={transform}
                      onMouseDown={(e) => handleFretMouseDown(fretNumber, e)}
                      onMouseEnter={() => handleFretMouseEnter(fretNumber)}
                      onMouseLeave={(e) => handleFretMouseLeave(fretNumber, e)}
                      onTouchStart={(e) => handleFretTouchStart(fretNumber, e)}
                      onTouchMove={(e) => handleFretTouchMove(e)}
                    />
                    <text
                      x={verticalX}
                      y={verticalY }
                      textAnchor="middle"
                      alignmentBaseline="central"
                      fill={theme.stringColor}
                      fontSize={nodeRadius * 1} // Always small for fret numbers
                      opacity={finalOpacity}
                      transform={transform}
                      style={{
                        cursor: 'pointer',
                        fontWeight: isSelected ? 'bold' : 'normal',
                        pointerEvents: 'none'
                      }}
                    >
                      {fretNumber}
                    </text>
                  </g>
                );
              })}

            {/* Bottom left circle */}
            {(() => {
              const firstString = stringLinePositions[0];
              const bottomPaddingCenterY = dimensions.height + (PADDING.bottom / 2) + 
                (!isVertical && showNodes ? nodeRadius * 0.5 - (bottomStringThickness / 2) : 0);
              
              const verticalX = lerp(
                -PADDING.left * 0.5,  // Align with string labels in horizontal mode
                dimensions.height - firstString - verticalCenterOffset,  // Align with string labels in vertical mode
                orientationProgress
              );
              const verticalY = lerp(
                bottomPaddingCenterY,  // Use same Y position as fret labels in horizontal mode
                firstFret + firstFretOffset,  // Vertical mode position
                orientationProgress
              );

              return (
                <g>
                  {/* Larger background square */}
                  <rect
                    x={verticalX - nodeRadius * 2}  // Offset by radius*2 to center
                    y={verticalY - nodeRadius * 2}  // Offset by radius*2 to center
                    width={nodeRadius * 4}          // Diameter = radius * 2 * 2
                    height={nodeRadius * 4}         // Diameter = radius * 2 * 2
                    fill="transparent"              // Light green color
                    style={{ position: 'absolute' }}
                    onClick={toggleLabelFiltering}
                  />
                  {/* Original circle */}
                  <circle
                    cx={verticalX}
                    cy={verticalY}
                    r={nodeRadius * 0.125}
                    fill="transparent"              // Light green color
                  />
                </g>
              );
            })()}
          </g>
        </svg>
  );
});

// Make sure the display name is set
DiagramSVG.displayName = 'DiagramSVG';

export default DiagramSVG;